import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';

import { IMAGE_RESIZE_CLASS_NAME, IMAGE_V2_CLASS_NAME, PHONE_MAX_WIDTH } from '../../../../components/Photo/constants';
import dom from '../../../wrapper/DomWrapper';
import { LAZY_LOADED_EVENT } from '../../LazyLoad/constants';

const cutNumber = require('../../../../../helpers/cutNumber');

const updateHeight = (elWrapper, elImage) => {
  elWrapper.style.removeProperty('height');

  if (dom.hasClass(elWrapper, 'picture-wrap_unavailable')) return;

  const elWrapperHeight = Math.round(elWrapper.offsetHeight);
  const elImageHeight = Math.round(elImage.offsetHeight);
  const isNeedUpdate = elImageHeight < elWrapperHeight;

  if (!isNeedUpdate) return;

  dom.updateStyle(elWrapper, { height: `${elImageHeight}px` });
};

const updateScale = (elWrapper, elPicture, elImage) => {
  if (window.innerWidth <= PHONE_MAX_WIDTH) {
    if (!elPicture.dataset.transform) return;

    const elWrapperWidth = elWrapper.offsetWidth;
    const scale = cutNumber(elWrapperWidth / elImage.naturalWidth);

    if (!scale) return;

    const transform = JSON.parse(elPicture.dataset.transform);
    const phoneTransform = transform.image_phone;
    const x = cutNumber(phoneTransform.x - 100);
    const y = cutNumber(phoneTransform.y - 100);

    if (isNil(x) || isNil(y)) return;

    dom.updateStyle(elImage, {
      transform: `translate(${x}%, ${y}%) scale(${scale})`,
    });
  } else {
    elImage.style.removeProperty('transform');
  }
};

/**
 *
 * @param {HTMLElement[]} data
 */
const update = (data) => {
  const [elWrapper, elPicture, elImage] = data;
  const isContain = dom.hasClass(elWrapper, 'picture-wrap_contain');
  const isCover = dom.hasClass(elWrapper, 'picture-wrap_cover');
  const isNotResize = !dom.hasClass(elWrapper, IMAGE_RESIZE_CLASS_NAME);

  if (isContain) {
    updateHeight(elWrapper, elImage);
  }

  if (isNotResize && isCover) {
    updateScale(elWrapper, elPicture, elImage);
  }
};

/**
 *
 * @param {[HTMLElement[]]} buffer
 * @returns {(function(): void)|*}
 */
const handleResize = (buffer) => () => {
  for (const data of buffer) {
    update(data);
  }
};

const fixImages = () => {
  const collection = dom.getCollection(`.picture-wrap.${IMAGE_V2_CLASS_NAME}`) || [];
  const elements = [...collection];

  if (elements.length === 0) return;

  const buffer = [];

  for (const elWrapper of elements) {
    const elPicture = dom.getElement('picture.lazy', elWrapper);

    if (!elPicture) continue;

    dom.on(elPicture, LAZY_LOADED_EVENT, () => {
      const elImage = dom.getElement('.picture__fill', elPicture);

      if (!elImage) return;

      const data = [elWrapper, elPicture, elImage];

      update(data);
      buffer.push(data);
    });
  }

  dom.on(dom.window, 'resize', debounce(handleResize(buffer), 34));
};

export default fixImages;
